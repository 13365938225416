@tailwind base;
@tailwind components;
@tailwind utilities;

:root{
  --themColor:#EEEEEE;
  --secondaryColor:#430A5D;
  --darkColor:#76ABAE;
}

body {
  margin: 0;
  font-family: "Tajawal", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}



.active{
  color:#6466f1;
}

.footer-report{display: none;}
.header-report{display: none;}



.primary-bg-color{
  background-color: var(--themColor)!important;
}
.primary-color{
  color: var(--themColor) !important;
}
.secondary-bg-color{
  background-color: var(--secondaryColor) !important;
}
.secondary-color{
  color: var(--secondaryColor) !important;
}
.menu-border-color{
  border-color: var(--darkColor);
}
/* Butons */
.primary-btn{
  border-width: 1px ;
  border-color: var(--themColor);
  color: var(--themColor);
}

.ce-block__content,
    .ce-toolbar__content {
        max-width: unset;
    }


    .header-space {
      height: 215px;
      display: none;  
    }
    .headers-space {
      height: 160px;
      display: none;  
    }
    .footer-space {
      height: 80px;
      display: none;  
    }
    
    .footers-space {
      height: 50px;
      display: none;  
    }
    
    /* للعرض العادي */
    /* .header {
      position: relative;
      width: 100%;
      margin: 0 auto;
      z-index: 100;  
    }
    .footer {
      position: relative;
      width: 100%;
      margin: 0 auto;
    } */

    .header, .footer {
      display: none; /* Hide by default in regular view */
    }
    
.page {
  /* padding: 20px; */
  /* /* page-break-before: always; */
  
}

@page {
  margin: 20px;
}

/* @media print {
  thead {display: table-header-group;} 
  tfoot {display: table-footer-group;}
  
  .header-space, .footer-space {
    display: block;  
  }

  .invoice-terms {
    page-break-before: always;
    margin-top: 20px;
  }
  
   .invoice-terms .header,
  .invoice-terms .footer {
    display: none;
  }
  
  .header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    margin: 0;
    padding: 10px 5%;
  }
  .footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    margin: 0;
    padding: 10px 5%;
  }
} */

@media print {
  
  /* تعريفات الرأس والتذييل */
  .header {
    display: block;  
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    margin: 0;
    padding: 0px 1%;
    z-index: 100;
  }
  
  .footer, .footers {
    display: block;  
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    margin: 0;
    padding: 15px 1%;
    z-index: 100;
  }
  .header-report{
    position: fixed;
    display: block;
    left: 0;
    top: 0;
    width: 100%;
    margin: 0;
    padding: 0px 1%;
    z-index: 100;
  }
  
  .footer-report {
    display: block;  
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    margin: 0;
    padding: 15px 1%;
    z-index: 100;
  }
  
  /* حجز مساحة للرأس والتذييل */
  .headers-space {
    display: block;
    height: 170px;  
  }
  
  .footers-space {
    display: block;
    height: 60px;  
  }
  .footers-space-report {
    display: block;  
    position: fixed;
    height: 80px; /* تحديد ارتفاع معين بدلاً من auto */
    flex-shrink: 0;

  }
  
  .headers-space-report {
    display: block !important;
    height: 120px !important; /* زيادة الارتفاع */
    margin-top: 0; /* إزالة margin-top */
  }

  /* .sales-report-content {
    page-break-inside: avoid;
    margin-bottom: 160px;
  } */

  .main-content {
    position: relative;
  flex:1;
    overflow: inherit;
    margin-top: 90px !important;
    margin-bottom: 110px !important;
    page-break-inside: auto;
    page-break-after: auto;
  }
  
  
  .header-space {
    display: block;
    height: 230px;  
  }
  
  .footer-space {
    display: block;
    height: 70px;  
  }
  
  /* ضمان عرض الهيدر والفوتر بشكل صحيح */
  thead {
    display: table-header-group;
  }
  
  tfoot {
    display: table-footer-group;
  }
  
  /* منع قطع الصفوف */
  table {
    page-break-inside: auto;
    border-collapse: collapse;
  }
  
  tr {
    page-break-inside: avoid !important;
  }
  
  /* منع قطع عناصر صفوف المنتجات */
  div.border-bb, 
  div[class*="flex justify-between"], 
  div[class*="flex items-center"] {
    page-break-inside: avoid !important;
    /* padding-bottom: 5px !important; */
  }
  
  /* الشروط والأحكام في صفحة جديدة */
  .invoice-terms {
    page-break-before: always !important;
    padding-top: 20px;
  }
  
  /* ضبط أحجام العناصر */
  div.flex-1.p-2.border-e {
    flex: 2 !important;
    /* min-width: 130px !important; */
  }
  
  div.w-12 {
    width: 40px !important;
    min-width: 40px !important;
    padding: 2px 4px !important;
  }
  
  div.w-14 {
    width: 55px !important;
    min-width: 55px !important;
    padding: 2px 4px !important;
  }
  
  div.w-20 {
    width: 50px !important;
    min-width: 50px !important;
    padding: 2px 4px !important;
  }
  
  div.w-24 {
    width: 75px !important;
    min-width: 75px !important;
    padding: 2px 4px !important;
  }
  
  /* رأس في المكان الصحيح */
  div.flex.mt-5.justify-between.items-center.bg-slate-100.text-xs.font-bold.border.rounded-t {
    margin-top: 25px !important;
    display: flex !important;
    page-break-inside: avoid !important;
    page-break-before: auto !important;
  }


  .hover\:bg-slate-100:hover,
  .hover\:font-bold:hover,
  div:hover {
    background-color: transparent !important;
    font-weight: normal !important;
  }
  
  /* تحديد صريح لإلغاء التأثير على عناصر الصفوف */
  .sales-report-row:hover,
  .p-3.flex.gap-3.justify-between.items-center.text-xs.border-t.border-slate-300:hover {
    background-color: transparent !important;
    font-weight: normal !important;
  }
  
  /* إلغاء كل classes الهوفر للطباعة */
  *:hover {
    background-color: initial !important;
    font-weight: initial !important;
  }
}
body > iframe {
  display: none;
}